.main-menu {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}
@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.header-bar-component{
    position: fixed;
    right: 0;
    top: 0;
}
.main-menu-dot{
    position: absolute;
    background: #69BA7B;
    margin: -23px 0px 0px 111px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: 9997;
}
.main-menu-top{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 80px;
    margin: 0 auto;
}
.mainMenuColor{
    width: 100%;
    height: 80px;

}

.all-main-menu{
    display: flex;
    justify-content: center;
}

.main-menu-top{
    max-width: 1265px;
}
.main-menu{
    position: fixed;
    top: 0; left: 0; right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: #FFFFFF;
    width: 100%;
    height: 80px;
    margin: 0 auto;
    z-index: 20;
}

.main-menu-icon-top{
    background-image: url(../assets/headerBarLogoIpadWhite.png);
    width: 160px;
    height: 52px;
    background-repeat: no-repeat;
}

.main-menu-icon{
    background-image: url(../assets/headerBarLogoIpad.svg);
    width: 160px;
    height: 52px;
    background-repeat: no-repeat;
}

.main-menu-items{
    display: flex;
    justify-content: space-around;
}

.main-menu-item{
    text-decoration:none;
    font-family: 'Inter',header sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #2E63A1;
    padding: 0 20px;
    position: relative;
}
.main-menu-item-top {
    text-decoration:none;
    font-family: 'Inter',header sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 0 20px;
    position: relative;
}
.main-menu-item:hover{
    color: #91A4BB;
}

.main-menu-phone-top{
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
}

.main-menu-phone{
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #2E63A1;
    
}

.main-menu-button-top{
    display: none;
}

.main-menu-button{
    display: none;
}


@media only screen and (max-width : 1399px){
    .main-menu{
        width: 100%;
    }

    .main-menu-icon-top{
        margin-left: 20px;
    }

    .main-menu-icon{
        margin-left: 20px;
    }

    .main-menu-top{
        width: 100%;
    }

    .main-menu-button-top{
        display: block;
        width: 70px;
        height: 40px;
        background-image: url(../assets/MenuBurgerIPadWhite.svg);
        background-repeat: no-repeat;
        background-position:center;
        margin-right: 20px;
    }

    .main-menu-button{
        display: block;
        width: 70px;
        height: 40px;
        background-image: url(../assets/MenuBurgerBlueIPad.svg);
        background-repeat: no-repeat;
        background-position:center;
        margin-right: 20px;
    }

    .main-menu-items{
        display: none;
    }

    .main-menu-phone-top{
        font-size: 22px;
        padding-left: 175px;
    }

    .main-menu-phone{
        font-size: 22px;
        margin-left: 180px;
    }
}

@media only screen and (max-width : 740px){
    .main-menu{
        height: 60  px;
    }

    .main-menu-button-top{
        width: 30px;
        height: 25px;
        background-image: url(../assets/MenuBurgerIPhoneWhite.svg);
        margin-right: 0;
    }

    .main-menu-button{
        width: 30px;
        height: 25px;
        background-image: url(../assets/MenuBurgerBlueIPhone.svg);
        margin-right: 0;
    }

    .main-menu-icon-top{
        background-size: 100%;
        width: 120px;
        height: 40px;
        background-repeat: no-repeat;
        margin-left: 0;
    }


    .main-menu-icon{
        background-image: url(../assets/headerBarLogoIpad.svg);
        background-size: 100%;
        width: 120px;
        height: 40px;
        background-repeat: no-repeat;
        margin-left: 0;
    }

    .main-menu-phone-top{
        font-size: 16px;
        padding-left: 0px;  
    }

    .main-menu-phone{
        font-size: 16px;
        margin-left: 0;
    }
}