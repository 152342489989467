.bar-dot{
    position: absolute;
    background: #69BA7B;
    margin: -26px 0px 0px 123px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.bar-form{
    display: flex;
    flex-direction: column;
    height: 652px;
    width: 360px;
    background: #FFFFFF;
}

.bar-logo-block{
    display: flex;
    justify-content: space-between;
    padding: 0 46px;
}

.bar-logo{
    background-image: url(../assets/headerBarLogoIpad.svg);
    width: 160px;
    height: 52px;
    background-repeat: no-repeat;
    margin-top: 30px;
}

.bar-close{
    background-image: url(../assets/headerBarClose.svg);
    width: 33px;
    height: 33px;
    background-size: 98%;
    background-repeat: no-repeat;
    margin-top: 41px;
}

.bar-title{
    padding-top: 50px;
    padding-bottom: 12px;
    padding-left: 45px;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: #2E63A1;
}

.bar-text{
    padding-bottom: 5px;
    padding-left: 65px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    text-decoration:none;
    position: relative;
}

.bar-phone{
    padding-bottom: 5px;
    padding-left: 65px;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    color: #333333;
}

.bar-mail{
    padding-bottom: 5px;
    padding-left: 65px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}

@media only screen and (max-width : 740px){

    .bar-dot{
        margin: -24px 0px 0px 108px;
    }

    .bar-form{
        display: flex;
        flex-direction: column;
        height: 580px;
        width: 320px;
        background: #FFFFFF;
    }

    .bar-logo-block{
        padding: 0 41px;
    }

    .bar-logo{
        width: 140px;
        height: 45px;
        background-size: 100%;
    }
    
    .bar-close{
        width: 30px;
        height: 30px;
    }
    
    .bar-title{
        padding-top: 45px;
        padding-bottom: 10px;
        padding-left: 40px;
        font-size: 18px;
    }
    
    .bar-text{
        padding-bottom: 5px;
        padding-left: 60px;
        font-size: 14px;
    }
    
    .bar-phone{
        padding-bottom: 5px;
        padding-left: 60px;
        font-size: 20.1263px;
    }
    
    .bar-mail{
        padding-bottom: 5px;
        padding-left: 60px;
        font-size: 14px;
    }
}