.calendar-block{
    display: grid;
    width: 100%;
    height: auto;
    grid-template-rows: 65px auto auto;
    grid-template-columns: 100%;
    background: #F2F2F2;
}

.custom-calendar{
    grid-row: 2/3;
    width: 286px;
    display: grid;
    min-height: 325px;
    grid-template-columns: 100%;
    justify-self: center;
}

.calendar-reqest{
    grid-row: 3/4;
    display: grid;
    width: 100%;
    min-height: 204px;
    grid-template-rows: 9% 29% 17% 12% 11% 14% 8%;
    grid-template-columns: 7% 2% 4% 78% 9%;
}

.calendar-info-logo{
    grid-row: 5/6;
    grid-column: 2/4;
    background-image: url(../assets/headerInfo.svg);
    background-repeat: no-repeat;
}

.request-info{
    grid-row: 4/7;
    grid-column: 3/5;
    padding: 12px 20px 0px 33px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #282828;
    background: #FFFFFF;
}

.request-button{
    grid-row: 2/3;
    grid-column: 3/5;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #2E63A1;
    padding-top: 20px;
    cursor: pointer;
}

.calendar-date{
    grid-row: 1/2;
    padding-top: 26px;
    background: #2E63A1;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}



@media only screen and (max-width : 1399px) and (min-width : 740px){
    .calendar-block{
        grid-template-rows: 31% 69%;
        grid-template-columns: 50% 50%;
    }

    .custom-calendar{
        grid-row: 1/4;
        width: 286px;
        padding-bottom: 20px;
    }

    .calendar-date{
        grid-row: 1/2;
        grid-column: 1/2;
        font-size: 24px;
        line-height: 25px;
        padding-right: 70px;
        padding-left: 41px;
        padding-top: 35px;
        
        text-align: start;
    }

    .calendar-reqest{
        grid-row: 2/4;
        grid-column: 1/2;
        background: #2E63A1;
        min-height: 239px;
        grid-template-rows: 12% 8% 41% 25% 13%;
        grid-template-columns: 12% 7% 68% 12%;
    }

    .request-button{
        grid-row: 4/5;
        grid-column: 2/4;
        background: #FFFFFF;

        font-size: 14px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #2E63A1;
    }

    .request-info{
        grid-row: 3/4;
        grid-column: 2/5;
        padding-top: 13px;
        padding-right: 70px;
        padding-left: 0;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        background: #2E63A1;
    }

    .calendar-info-logo{
        background-image: url(../assets/headerInfoIPad.svg);
        grid-row: 2/3;
        grid-column: 2/3;
    }
}

@media only screen and (max-width: 739px){
    .calendar-reqest{
        grid-template-rows: 9% 29% 10% 14% 10% 14% 14%;
        grid-template-columns: 6% 2% 4% 81% 7%;
    }

    .request-info{
        padding: 15px 20px 0px 33px;
    }
}

/* _________________________________calendar - order__________________________ */

.calendar-order-form {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

.calendar-order-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 594px;
    width: 100%;
    background: #F2F2F2;
    text-align: center;
}

.calendar-order-form-title{
    margin-top: 60px;
    padding: 0 40px;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 30px;
    line-height: 31px;
    text-transform: uppercase;
    color: #2E63A1;
}

.calendar-order-form-text{
    margin-top: 30px;
    padding: 0 45px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}

.calendar-order-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 93px;
}

.calendar-order-input-name{
    width: 265px;
    height: 60px;
    padding: 0 0 0 25px;
    margin-bottom: 10px;
    border: none;
    font-size: 14px;
    line-height: 22px;
}

.calendar-order-input{
    width: 265px;
    height: 60px;
    padding: 0 0 0 25px;
    margin-bottom: 10px;    
    border: none;
    font-size: 14px;
    line-height: 22px;
}

.calendar-order-input-red{
    width: 265px;
    height: 60px;
    padding: 0 0 0 25px;
    margin-bottom: 10px;    
    border: none;
    font-size: 14px;
    line-height: 22px;
    color: #FF0024 !important;
}


.calendar-order-button{
    width: 295px;
    height: 60px;
    background: #2E63A1;
    border: 3px solid #FFFFFF;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}

@media only screen and (max-width : 1399px) and (min-width : 740px){
    .calendar-order-form{
        flex-direction: row;
        justify-content: space-between;
        height: 345px;
        align-items:flex-start;
        text-align: start;
    }

    .calendar-order-form-text{
        padding: 0 40px;
        width: 240px;
    }

    .calendar-order-inputs{
        padding-right: 40px;
    }

    .calendar-order-input-name{
        width: 300px;
        padding: 0 0 0 30px;
        margin-bottom: 10px;
        border: none;
    }
    
    .calendar-order-input{
        width: 300px;
        padding: 0 0 0 30px;
        margin-bottom: 10px;    
    }
    .calendar-order-input-red{
        width: 300px;
        padding: 0 0 0 30px;
        margin-bottom: 10px; 
        color: #FF0024 !important;
    }
    .calendar-order-button{
        width: 334px;
    }
}

@media only screen and (max-width: 739px){
    .calendar-order-input-name{
        width: 260px;
        padding: 0 0 0 25px;
    }
    
    .calendar-order-input{
        width: 260px;
        padding: 0 0 0 25px;
    }
    .calendar-order-input-red{
        width: 260px;
        padding: 0 0 0 25px;
        color: #FF0024 !important;
    }
    .calendar-order-button{
        width: 290px;
    }
}


/* ---------------------------------- calendar ----------------------------- */
.react-calendar__month-view__weekdays__weekday{
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    text-transform:capitalize;
}

.custom-calendar .react-calendar{
    border: 0;
    background: #F2F2F2;
}

.custom-calendar .react-calendar__navigation__arrow, .react-calendar__navigation__next-button{
    min-width: 26px;
}

.custom-calendar .react-calendar__navigation{
    height: 69px;
}

.custom-calendar .react-calendar__navigation__label{
    order: -1;
    min-width: 250px;
    height: 77px;
    margin-left: -3px;
}


@media only screen and (max-width : 1399px) and (min-width : 740px){
    .custom-calendar .react-calendar__navigation{
        height: 63px;
    }

    .custom-calendar .react-calendar__navigation__label{
        height: 63px;
    }
}


.react-calendar__navigation__label__labelText{
    display: flex;
    justify-content: start;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
}

abbr {
    text-decoration: none;
}


.calendar-block .custom-calendar .react-calendar__tile--now:enabled:hover .react-calendar__tile--now:enabled:focus {
    background: #2E63A1;
    color: #FFFFFF;
  }

  .calendar-block .custom-calendar .react-calendar .react-calendar__tile--now{
    background: #F2F2F2;
    color: #088D26;
    font-family: 'Rubik', sans-serif;
  }

  .custom-calendar .react-calendar__tile, .react-calendar__month-view__days__day{
    border: 1px solid #DFDFDF;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Rubik', sans-serif;
}

.custom-calendar .react-calendar__month-view__days{
    padding-top: 7px;
}


.custom-calendar .first-3-day {
    color: #088D26;
    font-family: 'Rubik', sans-serif;
 }

.react-calendar__tile--active {
    color: #FFFFFF !important;
    background-color: #2E63A1 !important;
}

.custom-calendar .second-3-day {
    color: #D8A800;
    font-family: 'Rubik', sans-serif;
 }

 .custom-calendar .react-calendar__month-view__days .hide-day{
    color: #B7B7B7;
    font-family: 'Rubik', sans-serif;
 }

.custom-calendar .hide-day{
    color: #B7B7B7;
}
 

 .custom-calendar .react-calendar__month-view__days__day--weekend{
    color: #333333;
    font-family: 'Rubik', sans-serif;
 }

.react-calendar__month-view__days__day{
    color: #D80000;
 }

 .arrow-next{
    height: 14px;
    width: 8.5px;
    background-image: url(../assets/arrowNext.svg);
    background-repeat: no-repeat;
    background-size: 103%;
 }

.react-calendar__navigation__prev-button:disabled .arrow-last{
    height: 14px;
    width: 8.5px;
    background-image: url(../assets/arrowLast.svg);
    background-repeat: no-repeat;
    background-size: 100%;  
}

.react-calendar__navigation__prev-button:enabled .arrow-last{
    height: 14px;
    width: 8.5px;
    background-image: url(../assets/arrowNext.svg);
    transform:rotate(180deg);
    background-repeat: no-repeat;
    background-size: 100%;  
}


.calendar-block .custom-calendar .react-calendar .react-calendar__tile--now:hover{
    background: #e6e6e6;
}
.calendar-block .custom-calendar .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
    background: #F2F2F2;
}

.calendar-block .custom-calendar .react-calendar__navigation button:enabled:focus{
    background: #F2F2F2;
}
