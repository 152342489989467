::placeholder {
    font-family: 'Rubik', sans-serif;
    color: #333333;
    font-size: 14px;
    opacity:  1;
}

.order-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 370px;
    width: 100%;
    background: #2E63A1;
    color: #FFFFFF;
}

.order-logo-group{
    position: absolute;
    right: 0;
    background-position-x: 600px;
    background-image: url(../assets/orderLogoGroup.svg);
    background-repeat: no-repeat;
    background-size: 57%;
    height: 100%;
    width: 1400px;
}

.order-form-wrapper {
    position: relative;
}

.order-form-title{
    padding-top: 90px;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 30px;
    line-height: 31px;
    width: 960px;
    text-transform: uppercase;
    letter-spacing: -0.1px;
}

.order-br{
    display: none;
}

.order-form-text{
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-transform: none;
    width: 960px;
    margin-top: 35px;
}

.order-inputs{
    width: 960px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 93px;
}

.order-input-name{
    width: 175px;
    height: 60px;
    padding: 0 0 0 25px;
    border: none;
    font-size: 14px;
    line-height: 22px;
}

.order-input{
    width: 205px;
    height: 60px;
    padding: 0 0 0 25px;
    border: none;
    font-size: 14px;
    line-height: 22px;
}

.order-input-red{
    width: 205px;
    height: 60px;
    padding: 0 0 0 25px;
    border: none;
    font-size: 14px;
    line-height: 22px;
    color: #FF0024 !important;
}

.order-button{
    width: 240px;
    height: 60px;
    background: #2E63A1;
    border: 3px solid #FFFFFF;
    font-family: 'GRIFTER';
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}

.br-tel{
    display: none;
}

@media only screen and (max-width : 1399px){
    .order-form{
        height: 510px;
    }

    .order-form-title{
      padding-top: 62px;
      width: 650px;
      text-align: center;
      font-size: 27px;
      margin: 0;
      letter-spacing: 2px;
    }

    .order-br{
        display: none;
    }

    .order-logo-group{
        background-size: 78.5%;
    }

    .order-form-text{
        width: 100%;
        text-align: center;
        margin-top: 12px;
    }

    .order-inputs{
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 52px;
    }

    .order-input-name{
        width: 325px;
        margin-bottom: 10px;
    }

    .order-input{
        width: 325px;
        margin-bottom: 10px;
    }

    .order-input-red{
        width: 325px;
        margin-bottom: 10px;
        color: #FF0024 !important;
    }
    .order-button{
        width: 350px;
    }
  }

@media only screen and (max-width : 740px){
    .order-form-title{
      font-size: 23px;
      line-height: 25px;
      padding-top: 48px;
      width: 325px;
      margin: 0px 13px 0 13px;
      letter-spacing: 0;
    }
    .order-br{
        display: block;
    }
    .order-form-text{
        font-size: 16px;
        line-height: 20px;
        padding-top: 5px;
        width: 240px;
        margin: 18px 55px;
    }

    .order-inputs{
        margin-top: 30px;
    }

    .order-input-name{
        width: 305px;
        padding: 0 0 0 30px;
    }

    .order-input{
        width: 305px;
        padding: 0 0 0 30px;
    }
    .order-input-red{
        width: 305px;
        padding: 0 0 0 30px;
        color: #FF0024 !important;
    }

    .order-button{
        width: 335px;
    }

    .br-tel{
        display: block;
    }
}
